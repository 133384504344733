import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudySecretSales from "../../components/info-site/CaseStudySecretSales";
import GetStarted from "../../components/info-site/GetStarted"

const SecretSales = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="secretSalesCaseTitle" descriptionId="secretSalesCaseSubTitle" />
            <CaseStudySecretSales
              title="secretSalesCaseTitle"
              subTitle="secretSalesCaseSimpleSubTitle"
            />

            <GetStarted light />
        </Layout>
    );
};

export default SecretSales;