import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudySecretSales.module.css";

// import secretSalesImg from "../../assets/secretSales-deodorants.jpeg"
import secretSalesImg from "../../assets/secret-sales-1.jpg"
import secretSales2Img from "../../assets/secret-sales-2.jpg"
import tyvisoLogo from "../../assets/simple-logo.png"

import TestimonoalImgOne from "../../assets/secret-sales-3.jpeg"
import TestimonoalImgTwo from "../../assets/adrian.jpg"

const testimonials = [
  {
    id: 'secretSales',
    img: TestimonoalImgOne,
    alt: 'Alex Adamson, CMO, SecretSales',
    text: '"Working with Tyviso and their proprietary technology to be able to help bring affinity third party offers to our customers has been instrumental in both improving our performance and customer proposition over the last 6 months. \n\n It\'s important that when we identify secondary revenue streams for the business that we do so in a way which is seamless or helps improve our overall customer experience and proposition, which Tyviso unlocks for us."',
    author: 'Alex Adamson, CMO, SecretSales'
  },
  {
    id: 'tyviso',
    img: TestimonoalImgTwo,
    alt: 'Adrian Vella, CEO, Tyviso',
    text: '"The Secret Sales customer is very smart when it comes to looking for good value. It’s clear that Gift With Purchase has helped solidify this value by the incredible results delivered."',
    author: 'Adrian Vella, CEO, Tyviso'
  }
]

const resultList = [
  {
    bold: '66%',
    normal: ' increase in basket completion',
  },
  {
    pre: 'Increase in order value of  ',
    bold: '14 % '
  },
  {
    normal: 'Double digital claim rate on offers',
  },
]

function CaseStudySecretSales({ title, subTitle }) {
  return (
    <div className={styles.CaseStudy}>
      <h2 className={styles.CaseStudyTitle}>
        <FormattedMessage id={title} />
      </h2>

      <div className={styles.CaseStudyFeaturedWrapper}>
        <img
          className={styles.CaseStudyFeaturedImg}
          src={secretSalesImg}
          alt="secretSales"
        />
        <div className={styles.CaseStudyFeaturedContent}>
          <h3 className={styles.CaseStudySubTitle}>
            <FormattedHTMLMessage id={subTitle} />
          </h3>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="secretSalesCaseFeaturedTextOne" />
          </p>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="secretSalesCaseFeaturedTextTwo" />
          </p>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="secretSalesCaseFeaturedTextThree" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="secretSalesCaseObjectiveTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="secretSalesCaseObjectiveDescription" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="secretSalesCaseObjectiveItemOne" />
            </li>
            <li>
              <FormattedMessage id="secretSalesCaseObjectiveItemTwo" />
            </li>
            <li>
              <FormattedMessage id="secretSalesCaseObjectiveItemThree" />
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="secretSalesCaseApproachTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="secretSalesCaseApproachItemOne" />
          </p>
          <p>
            <FormattedMessage id="secretSalesCaseApproachItemTwo" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudySimply}>
        <img src={secretSales2Img} alt="" />
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="secretSalesCaseResultsTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="secretSalesCaseResultsItemOne" />
          </p>
          <ul>
            {
              resultList.map(({pre, bold, normal}, idx) => (
                <li key={`result-list--${idx}`}>
                  {pre}{bold ? <strong>{bold}</strong> : null}{normal}
                </li>
              ))
            }
          </ul>
          <p>
            <FormattedHTMLMessage id="secretSalesCaseResultsItemFour" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyTestimonialWrapper}>
        {
          testimonials.map(item => (
            <div className={styles.CaseStudyTestimonial}>
              <div className={styles.CaseStudyTestimonialImage}>
                <img
                  src={item.img}
                  alt={item.alt}
                />
              </div>
              <div className={styles.CaseStudyTestimonialContent}>
                <p>{item.text}</p>
                <h3>{item.author}</h3>
              </div>
          </div>
          ))
        }
      </div>
    </div>
  );
};

export default CaseStudySecretSales;